import { createSlice } from "@reduxjs/toolkit";
const { REACT_APP_ENABLE_AVAILABILITY } = process.env;

const initialState = {
  display: true,
  touchMode: false,
  availableOnly: REACT_APP_ENABLE_AVAILABILITY === "true",
  searchString: "",
  minHeight: 0,
  maxHeight: 50,
  genus: {
    tulip: false,
    daffodil: false,
    hyacinth: false,
    allium: false,
    specialty: false,
  },
  bloomTime: {
    "Very-Early": false,
    Early: false,
    "Early-Mid": false,
    Mid: false,
    "Mid-Late": false,
    Late: false,
    "Very-Late": false,
  },
  color: {
    yellow: false,
    apricot: false,
    orange: false,
    red: false,
    pink: false,
    purple: false,
    lavender: false,
    blue: false,
    green: false,
    cream: false,
    white: false,
    maroon: false,
  },
  bulbAttributes: {
    "deer-resistant": false,
    "shade-tolerant": false,
    "for-naturalizing": false,
    "for-cutting": false,
    "for-the-south": false,
  },
  bulbCategories: {
    tulip: {
      "tulip-blends": false,
      singles: false,
      "wild-tulips": false,
      "cubed-blends": false,
      "squared-blends": false,
      bedspreads: false,
      "perennial-tulips": false,
      "darwin-hybrids": false,
      "tulip-doubles": false,
    },
    daffodil: {
      "daffodils-for-naturalizing": false,
      "the-gold-standard": false,
      "daffodil-blends": false,
      "daffodil-doubles": false,
      uncommon: false,
      miniature: false,
      "landscape-size": false,
      fragrant: false,
      "daffodils-for-the-south": false,
    },
    specialty: {
      crocus: false,
      muscari: false,
      "color-your-grass": false,
      "other-spring-flowering-bulbs": false,
    },
  },
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    toggleDisplay(state, action) {
      if (typeof action?.payload === "boolean") {
        state.display = action.payload;
      } else {
        state.display = !state.display;
      }
    },
    toggleAvailability(state, action) {
      state.availableOnly = !state.availableOnly;
    },
    filterGenus(state, action) {
      for (const key in state.genus) {
        if (key === action.payload) {
          state.genus[key] = !state.genus[key];
          for (const category in state.bulbCategories[key]) {
            state.bulbCategories[key][category] = false;
          }
        } else {
          state.genus[key] = false;
        }
      }
    },
    filterColor(state, action) {
      for (const key in state.color) {
        if (key === action.payload) {
          state.color[key] = !state.color[key];
        }
      }
    },
    filterBulbCategories(state, action) {
      const genus = action.payload.genus;
      const category = action.payload.category;

      for (const key in state.bulbCategories[genus]) {
        if (key === category) {
          state.bulbCategories[genus][key] = !state.bulbCategories[genus][key];
        } else {
          state.bulbCategories[genus][key] = false;
        }
      }
    },
    filterBulbAttributes(state, action) {
      for (const key in state.bulbAttributes) {
        if (key === action.payload) {
          state.bulbAttributes[key] = !state.bulbAttributes[key];
        } else {
          state.bulbAttributes[key] = false;
        }
      }
    },
    filterBloomTime(state, action) {
      for (const key in state.bloomTime) {
        if (key === action.payload) {
          state.bloomTime[key] = !state.bloomTime[key];
        } else {
          state.bloomTime[key] = false;
        }
      }
    },
    setSearchString(state, action) {
      state.searchString = action.payload;
    },
    setHeight(state, action) {
      state.minHeight = action.payload.minHeight;
      state.maxHeight = action.payload.maxHeight;
    },
    setTouchMode(state, action) {
      state.touchMode = action.payload;
    },
    resetAllFilters(state, action) {
      const newState = initialState;
      return newState;
    },
  },
});

export const {
  toggleDisplay,
  toggleAvailability,
  filterGenus,
  filterBulbAttributes,
  filterBulbCategories,
  filterColor,
  filterBloomTime,
  setSearchString,
  setHeight,
  setTouchMode,
  resetAllFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
